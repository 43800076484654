import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

var originalPush: any = Router.prototype.push
var originalGo: any = Router.prototype.go
Router.prototype.push = function push(location: any) {
  return originalPush.call(this, location)
    .catch((err: any) => {
      if (err && err.name == 'NavigationDuplicated') {
        originalGo.call(this, location)
      }
    })
}
var originalReplace: any = Router.prototype.replace
Router.prototype.replace = function replace(location: any) {
  return originalReplace.call(this, location)
    .catch((err: any) => {})
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/admin/contact',
      name: 'contactconfirm',
      component: () => import('./views/admin/ContactConfirm.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'お問い合わせ' }
    },
    {
      path: '/admin/news',
      name: 'newsCreate',
      component: () => import('./views/admin/NewsCreate.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'ニュース' }
    },
    {
      path: '/guide',
      name: 'guide',
      component: () => import('./views/about/Guide.vue'),
      meta: { breadcrumbs: '使い方' }
    },
    {
      path: '/policy',
      name: 'policy',
      component: () => import('./views/about/UsePolicy.vue'),
      meta: { breadcrumbs: '利用規約' }
    },
    {
      path: '/privacy-policy',
      name: 'privacypolicy',
      component: () => import('./views/about/PrivacyPolicy.vue'),
      meta: { breadcrumbs: 'プライバシーポリシー' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/about/Contact.vue'),
      meta: { breadcrumbs: 'お問い合わせ' }
    },
    {
      path: '/news/:id',
      name: 'news',
      component: () => import('./views/about/News.vue'),
      meta: { breadcrumbs: '運営からのお知らせ' }
    },
    {
      path: '/noticelist',
      name: 'noticelist',
      component: () => import('./views/user/NoticeList.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'お知らせ' }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('./views/user/Profile.vue'),
      meta: { breadcrumbs: 'ユーザー情報' }
    },
    {
      path: '/user-setting',
      name: 'userSetting',
      component: () => import('./views/user/SettingUser.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'アカウント設定' }
    },
    {
      path: '/main-registration/:onayamiid',
      name: 'userMainRegister',
      component: () => import('./views/user/SettingMainRegistration.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'ユーザー本登録' }
    },
    {
      path: '/clinic-setting',
      name: 'clinicSetting',
      component: () => import('./views/user/SettingClinic.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'クリニック設定'}
    },
    {
      path: '/unsupport',
      name: 'unsupport',
      component: () => import('./views/warn/UnsupportBrowser.vue'),
      meta: { breadcrumbs: '未対応のブラウザです' }
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'ホーム' }
    },
    {
      path: '/onayami/create',
      name: 'onayamiCreate',
      component: () => import('./views/onayami/OnayamiCreate.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'お悩み投稿' }
    },
    {
      path: '/onayami/create/:id',
      name: 'onayamiUpdate',
      component: () => import('./views/onayami/OnayamiCreate.vue'),
      meta: { requiresAuth: true, breadcrumbs: 'お悩み更新' }
    },
    {
      path: '/onayami/search',
      name: 'onayamiSearch',
      component: () => import('./views/onayami/OnayamiSearch.vue'),
      meta: { breadcrumbs: 'お悩みを探す' }
    },
    {
      path: '/onayami/list',
      name: 'onayamiList',
      component: () => import('./views/onayami/OnayamiList.vue'),
      meta: { breadcrumbs: '投稿一覧' }
    },
    {
      path: '/onayami/:id',
      name: 'onayamiDetail',
      component: () => import('./views/onayami/OnayamiDetail.vue'),
      meta: { breadcrumbs: 'お悩み詳細' }
    },
    {
      path: '/clinic/search',
      name: 'clinicSearch',
      component: () => import('./views/user/ClinicSearch.vue'),
      meta: { breadcrumbs: 'クリニック検索' }
    },
    {
      path: '/chatlist',
      name: 'chatlist',
      component: () => import('./views/chat/ChatList.vue'),
      meta: { requiresAuth: true, breadcrumbs: '相談室' }
    },
    {
      path: '/onayamis/:id/chat/:chatid',
      name: 'chat',
      component: () => import('./views/chat/Chat.vue'),
      meta: { requiresAuth: true, breadcrumbs: '相談' }
    },
    {
      path: '/',
      name: 'top',
      component: () => import('./views/about/Top.vue'),
      meta: { breadcrumbs: 'トップページ' }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: { breadcrumbs: 'ログイン' }
    },
    {
      path: '/clinic/login',
      name: 'clinic-login',
      component: () => import('./views/Login.vue'),
      meta: { breadcrumbs: 'クリニックログイン'}
    },
    {
      path: '*',
      name: '404',
      component: () => import('./views/warn/Page404.vue'),
      meta: { breadcrumbs: '404' }
    },
    {
      path: '/admin',
      name: 'admin-menu',
      component: () => import('./views/admin/AdminMenu.vue'),
      meta: { breadcrumbs: 'システム管理メニュー'}
    },
    {
      path: '/admin/user',
      name: 'user-admin',
      component: () => import('./views/admin/UserAdmin.vue'),
      meta: { breadcrumbs: 'ユーザー管理'},
    },
    {
      path: '/admin/comment',
      name: 'comment-admin',
      component: () => import('./views/admin/CommentAdmin.vue'),
      meta: { breadcrumbs: 'コメント管理'},
    },
    {
      path: '/admin/chat',
      name: 'chat-admin',
      component: () => import('./views/admin/ChatAdmin.vue'),
      meta: { breadcrumbs: 'チャット管理'},
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 500)
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

import firebase from 'firebase/app'
import 'firebase/auth'
import { UAParser } from 'ua-parser-js'
import imageCompression from "browser-image-compression"

export default class Common {
  static UserURL = ''
  static ALGORIA_APP_ID = process.env.VUE_APP_ALGORIA_APP_ID
  static ALGORIA_API_KEY = process.env.VUE_APP_ALGORIA_API_KEY
  static getUserId() {
    return (firebase.auth().currentUser || {uid: ''}).uid
  }

  static createUserIconURL(uid: string, fileExist: boolean) {
    if (fileExist) {
      return `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/` + encodeURIComponent(`users/${uid}/image`) + '?alt=media'
    } else {
      return ''
    }
  }

  static createURL(path: string) {
    return `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/` + encodeURIComponent(path) + '?alt=media'
  }

  static mainRegistUserDownloadURL() {
    return 'https://asia-northeast1-' + process.env.VUE_APP_FIREBASE_PROJECT_ID + '.cloudfunctions.net/exportUserDataForSF?uid='
  }

  static createIntegerRateIcon(rate: number) {
    let rateIcons: any[] = []
    if (!rate) {
      return rateIcons
    }

    for (let i=0; i < rate; i++) {
      rateIcons.push({iconGroup: 'fas', icon: 'star'})
    }

    for (let i = rateIcons.length; i < 5; i++) {
      rateIcons.push({iconGroup: 'far', icon: 'star'})
    }
    return rateIcons
  }

  static createRateIcon(rate: number) {
    let rateIcons = []
    if (!rate) {
      rateIcons = [{iconGroup: 'far', icon: 'star'}, {iconGroup: 'far', icon: 'star'}, {iconGroup: 'far', icon: 'star'}, {iconGroup: 'far', icon: 'star'}, {iconGroup: 'far', icon: 'star'}]
      return rateIcons
    }

    let num = Math.floor(rate)
    for (let i=0; i < num; i++) {
      rateIcons.push({iconGroup: 'fas', icon: 'star'})
    }
    let surplus = rate - num
    if (surplus > 0 && 0.5 > surplus) {
      rateIcons.push({iconGroup: 'fas', icon: 'star-half-alt'})
    } else if (surplus >= 0.5) {
      rateIcons.push({iconGroup: 'fas', icon: 'star'})
    }

    for (let i = rateIcons.length; i < 5; i++) {
      rateIcons.push({iconGroup: 'far', icon: 'star'})
    }

    return rateIcons
  }

  static imageExist(url: string) {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.onload = () => {resolve()}
      image.onerror = () => {reject()}
      image.src = url
      return url
    })
  }

  static getUserInfo() {
    return new Promise((resolve, reject) => {
      let uid = Common.getUserId()
      if (!uid) {
        resolve()
        return
      }

      let user = sessionStorage.getItem('user')
      if (user) {
        resolve(JSON.parse(user))
        return
      }

      firebase.firestore().collection('users').doc(Common.getUserId()).get().then((result) => {
        if (result.exists) {
          let userInfo = result.data()!
          userInfo.uid = Common.getUserId()
          sessionStorage.setItem('user', JSON.stringify(userInfo))
          resolve(userInfo)
        } else {
          resolve()
        }
      }).catch(() => {
        reject()
      })
    })
  }

  static getCategory() {
    return new Promise((resolve, reject) => {
      let category = sessionStorage.getItem('category')
      if (category) {
        resolve(JSON.parse(category))
        return
      }

      firebase.firestore().collection('categories').orderBy('value').get().then((result) => {
        let categories: any[] = []
        if (!result.empty) {
          result.docs.forEach((category) => {
            let obj = category.data()
            obj.id = category.id
            categories.push(obj)
          })
          sessionStorage.setItem('category', JSON.stringify(categories))
        }
        resolve(categories)
      }).catch(() => {
        reject()
      })
    })
  }

  static addTextCursor(textarea: any, text: string, selectionStart?: number) {
    let sentence = textarea.value
    let len      = sentence.length
    let pos      = selectionStart ? selectionStart : textarea.selectionStart
    var before   = sentence.substr(0, pos)
    var word     = text
    var after    = sentence.substr(pos, len)
    sentence = before + word + after
    return sentence
  }

  static isMobile() {
    return new UAParser().getDevice().type === 'mobile' || new UAParser().getDevice().type === 'tablet'
  }

  static isChrome() {
    return new UAParser().getBrowser().name == 'Chrome'
  }

  static useWebRTC() {
    let browser = new UAParser().getBrowser().name
    return browser == 'Chrome' || browser == 'Safari' || browser == 'Firefox' || browser == 'Edge' || Common.isMobile()
  }

  static createUUID() {  
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8)
      return v.toString(16)
    })
  }

  static createViewSkill(skills: any) {
    let skillArray: string[] = []
    for(let skill of skills) {
      skillArray.push(skill)
      if (skillArray.length >= 5) {
        break
      }
    }
    return skillArray
  }

  static getBlob(url: string) {
    return new Promise((resolve) => {
      var request = new XMLHttpRequest()
      request.open('GET', url, true)
      request.responseType = 'blob'
      request.onload = () => {
        let blob = request.response
        blob.lastModifiedDate = new Date()
        blob.url = url
        resolve(blob)
      }
      request.send()
    })
  }

  static getAddress(postCode: string) {
    return new Promise((resolve) => {
      var request = new XMLHttpRequest()
      request.open('GET', 'https://api.zipaddress.net/?zipcode=' + postCode, true)
      request.onload = () => {
        let address = JSON.parse(request.response)
        if (address.data) {
          resolve([address.data.pref, address.data.city, address.data.town])
        } else {
          resolve([])
        }
      }
      request.send()
    })
  }

  static async getCompressImageFileAsync(file: File, maxWidthOrHeight = 1920) {
    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: maxWidthOrHeight
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("getCompressImageFileAsync is error", error);
      throw error;
    }
  }

  static getPref() {
    return [
      {no: "13", name: "東京都"},
      {no: "01", name: "北海道"},
      {no: "02", name: "青森県"},
      {no: "03", name: "岩手県"},
      {no: "04", name: "宮城県"},
      {no: "05", name: "秋田県"},
      {no: "06", name: "山形県"},
      {no: "07", name: "福島県"},
      {no: "08", name: "茨城県"},
      {no: "09", name: "栃木県"},
      {no: "10", name: "群馬県"},
      {no: "11", name: "埼玉県"},
      {no: "12", name: "千葉県"},
      {no: "14", name: "神奈川県"},
      {no: "15", name: "新潟県"},
      {no: "16", name: "富山県"},
      {no: "17", name: "石川県"},
      {no: "18", name: "福井県"},
      {no: "19", name: "山梨県"},
      {no: "20", name: "長野県"},
      {no: "21", name: "岐阜県"},
      {no: "22", name: "静岡県"},
      {no: "23", name: "愛知県"},
      {no: "24", name: "三重県"},
      {no: "25", name: "滋賀県"},
      {no: "26", name: "京都府"},
      {no: "27", name: "大阪府"},
      {no: "28", name: "兵庫県"},
      {no: "29", name: "奈良県"},
      {no: "30", name: "和歌山県"},
      {no: "31", name: "鳥取県"},
      {no: "32", name: "島根県"},
      {no: "33", name: "岡山県"},
      {no: "34", name: "広島兼"},
      {no: "35", name: "山口県"},
      {no: "36", name: "徳島県"},
      {no: "37", name: "香川県"},
      {no: "38", name: "愛媛県"},
      {no: "39", name: "高知県"},
      {no: "40", name: "福岡県"},
      {no: "41", name: "佐賀県"},
      {no: "42", name: "長崎県"},
      {no: "43", name: "熊本県"},
      {no: "44", name: "大分県"},
      {no: "45", name: "宮崎県"},
      {no: "46", name: "鹿児島県"},
      {no: "47", name: "沖縄県"},
    ]
  }

}


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'cropperjs/dist/cropper.min.css'
import moment from 'moment'
require("moment-duration-format")
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import firebase from 'firebase/app'
import 'firebase/auth'
import BootstrapVue from 'bootstrap-vue'
import Common from './common/Common';
import SideMenuBar from './SideMenuBar.vue';
import "firebase/analytics";
Vue.component('side-menu-bar', SideMenuBar)

Vue.use(BootstrapVue)
//Vue.use(VueAnalytics, {
//  id: 'UA-155160614-1',
//  router
//})
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.set('theme','default');
library.add(fas)
library.add(fab)
library.add(far)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.filter('addComma', function(val: string){
	return val ? val.toLocaleString() : val;
})
Vue.filter('chatTime', function(val: Date){
  if (!val) {
    return ''
  }
  let date = new Date()
  if (date.getFullYear() != val.getFullYear()) {
    return moment(val).format('YYYY/MM/DD')
  } else if (date.getMonth() != val.getMonth() || date.getDate() != val.getDate()) {
    return moment(val).format('M/D')
  } else {
    return moment(val).format('H:mm')
  }
})
Vue.filter('dateTime', function(val: any){
	return val ? moment(val.toDate()).format('MM/DD HH:mm') : ''
})
Vue.filter('date', function(val: any){
	return val && val.toDate ? moment(val.toDate()).format('YYYY/MM/DD HH:mm') : (val ? moment(val).format('YYYY/MM/DD HH:mm') : '')
})
Vue.filter('newsDate', function(val: any){
	return val ? moment(val).format('YYYY/MM/DD HH:mm') : ''
})
Vue.filter('day', function(val: any){
	return val ? moment(val.toDate()).format('YYYY/MM/DD') : ''
})
Vue.filter('time', function(val: any){
	return val ? moment(val.toDate()).format('HH:mm') : ''
})
Vue.filter('rate', function(rate: any){
	return rate ? (Math.round(rate * 10) / 10).toFixed(1) : 'なし'
})
Vue.filter('qsDay', function(val: any){
	if (!val) {
    return ''
  }
  let date = new Date()
  if (date.getFullYear() != val.getFullYear()) {
    return moment(val).format('YYYY/MM/DD')
  } else if (date.getMonth() != val.getMonth() || date.getDate() != val.getDate()) {
    return moment(val).format('M/D')
  } else if (date.getHours() != val.getHours() && date.getTime() - val.getTime() >= 3600000) {
    return date.getHours() - val.getHours() + '時間前'
  } else {
    return Math.floor((date.getTime() - val.getTime()) / 60000) + '分前'
  }
})
Vue.filter('onayami', function(onayami: any){
  if (onayami) {
    if (onayami >= 1000000) {
      return Math.round(onayami / 100000) / 10 + 'M'
    } else if (onayami >= 1000) {
      return Math.round(onayami / 100) / 10 + 'K'
    }
    return onayami
  } else {
    return 0
  }
})

Vue.config.productionTip = false

var config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: `${process.env.VUE_APP_FIREBASE_APP_ID}`,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
firebase.initializeApp(config);
firebase.analytics();

var saveUser: any = null
let stateChange: any = null
router.beforeEach((to: any, from: any, next: any) => {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    stateChange = firebase.auth().onAuthStateChanged(function(user: any) {
      stateChange()
      if (user) {
        saveUser = user
        if (to.name == 'userSetting') {
          next()
          return
        }
        if (to.name == 'clinicSetting') {
          next()
          return
        }

        Common.getUserInfo().then((userInfo) => {
          if (userInfo) {
            next()
            return
          } 
          if (from.name == 'clinic-login'){
            next({ path: '/clinic-setting'})
            return
          }
          next({ path: '/user-setting'})
          return
        }).catch(() => {
          next()
          return
        })

      } else {
        if (saveUser) {
          saveUser = null
          next({ path: '/'})
        } else {
          next({ path: '/login', query: { redirect: to.fullPath }})
        }
      }
    })
  } else {
    if (to.name == 'top') {
      setTimeout(() => {
        stateChange = firebase.auth().onAuthStateChanged(function(user: any) {
          stateChange()
          if (user) {
            next({ path: '/home'})
          } else {
            next()
          }
        })
      }, 100)
      return
    }
    setTimeout(() => {
      next()
    }, 100)
  }
})

moment.locale("ja")

new Vue({
  router,
  render: (h: any) => h(App)
}).$mount('#app')

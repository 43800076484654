











































































































































































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Common from './common/Common'
import router from './router'
import moment from 'moment'
import Footer from "@/Footer.vue"
@Component({
  components: {
    Footer
  },
})
export default class App extends Vue {
  isLogin = false
  isLoading = true
  isMainLoading = false
  viewMenu = false
  viewQsCategory = false
  viewCsCategory = false
  viewTcCategory = false
  iconUrl = ''
  userInfo: any = {}
  searchType = 'onayami'
  searchValue = ''
  searchPrefecture = '東京都'
  categories = []
  qsSelectCategory = ''
  unsubscribe: any
  rooms: any = {}
  chatSum = 0
  stateChange: any = null
  canSearchSubmit: boolean = false
  pref: any = []
  beforeMount() {
    this.pref = Common.getPref()
    this.stateChange ? this.stateChange() : null
    this.stateChange = firebase.auth().onAuthStateChanged(user => {
      this.checkLogin()
      this.unsubscribe ? this.unsubscribe() : null

      if (user) {
        let uid = Common.getUserId()
        let oneMonthAgo = moment(new Date()).add(-30, 'days').toDate()
        this.unsubscribe = firebase.firestore().collectionGroup('rooms').where('uids', 'array-contains', uid).where('chatCreateTime', '>', oneMonthAgo).onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            let id = change.doc.id
            if (this.$router.currentRoute.name == 'chat' && this.$router.currentRoute.params.chatid == change.doc.id) {
              this.rooms[id] = 0
              this.chatSum = 0
              for (let key in this.rooms) {
                this.chatSum += this.rooms[key]
              }
            } else if (change.type === 'added' || change.type === 'modified') {
              let data = change.doc.data()!
              if (data.chatUid != uid) {
                this.rooms[id] = data.chatNum
              } else {
                this.rooms[id] = 0
              }
              if (data.clinic == uid && data.clinicChatNum) {
                this.rooms[id] += data.clinicChatNum
              } else if (data.patient == uid && data.studentChatNum) {
                this.rooms[id] += data.studentChatNum
              }
              this.chatSum = 0
              for (let key in this.rooms) {
                this.chatSum += this.rooms[key]
              }
            } else if (change.type === 'removed') {
              this.rooms[change.doc.id] = 0

              this.chatSum = 0
              for (let key in this.rooms) {
                this.chatSum += this.rooms[key]
              }
            }
            if (this.chatSum > 0) {
              document.title = `デンタルオンライン (${this.chatSum})`
            } else {
              document.title = `デンタルオンライン`
            }
          })
        })

        Common.getUserInfo().then((userInfo: any) => {
          this.userInfo = userInfo
          if (userInfo) {
            this.iconUrl = Common.createUserIconURL(userInfo.uid, userInfo.fileExist)
          }
          this.isLoading = false
        })
      } else {
        this.userInfo = {}
        this.isLoading = false
      }
    })
  }

  mounted() {
    Common.getCategory().then((categories: any) => {
      this.categories = categories
    })
  }

  search() {
    if (!this.canSearchSubmit) {
      return
    }
    let name
    if (this.searchType == 'onayami') {
      name = 'onayamiSearch'
    }
    this.$router.push({
      name: name,
      query: {
        text: this.searchValue,
        category: this.$route.query.category ? this.$route.query.category : '',
        prefecture: this.searchPrefecture,
        uid: this.$route.query.uid,
        page: '1'
        }
    })
    this.isLoading = true
    this.canSearchSubmit = false
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  }

  setCanSearchSubmit() {
    this.canSearchSubmit = true
  }

  checkLogin() {
    this.isLogin = Common.getUserId().length != 0
  }

  hideDropdown() {
    (<any>this.$refs.mainDropdown).hide(true)
  }

  hideSubDropdown() {
    (<any>this.$refs.subDropdown).hide(true)
  }

  logout() {
    firebase.auth().signOut()
    this.$router.push('/')
  }

  beforeDestroy() {
    this.unsubscribe ? this.unsubscribe() : null
  }

  getPref() {
    return [
      {no:1, name:"福岡県"}
    ]
  }
}
